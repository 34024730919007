@import "./assets/scss/variables.scss";

/* Import Bootstrap */
// @import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

/* Import Ng-Select */
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";

// /Users/mattrowntree/Desktop/dev/00_Eng_Projects/customer-portal-app/node_modules/bootstrap/scss/bootstrap.scss
// node_modules/bootstrap/scss/bootstrap.scss

/* Fonts */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Roboto"), local("Roboto-Regular"),
        url(assets/fonts/Roboto-Regular.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local("Roboto Medium"), local("Roboto-Medium"),
        url(assets/fonts/Roboto-Medium.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Bold"), local("Roboto-Bold"),
        url(assets/fonts/Roboto-Bold.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

@font-face {
    font-family: "Roboto Condensed";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Roboto Condensed Bold"), local("RobotoCondensed-Bold"),
        url(assets/fonts/RobotoCondensed-Bold.ttf) format("truetype");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
        U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
        U+FEFF, U+FFFD;
}

/* Components */
app-top-bar {
    flex: 0 0 auto;
}

/* Headings */
h1 {
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

h2,
h4,
h5,
h6 {
    font-weight: $font-weight-semibold;
}

h3 {
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

h3.modal-title {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-semibold;
    text-transform: unset;
}

h3,
h4,
h5,
h6 {
    margin-bottom: calc($spacer/2);
}

p {
    margin-bottom: calc($spacer/2);
}

/* Layout */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    background: $blue-gray-900;
}

body {
    min-height: 100vh;
    background: $blue-gray-900;
    line-height: normal; // Need this to fix mermaid
}

app-root {
    line-height: 1.5; // Need this to reset back to normal
}

.navbar {
    padding: calc($spacer/4) $spacer;
    height: $navbar-height;
}

.navbar a {
    color: $dark-primary;
}

.navbar-brand {
    padding: 0;
    font-size: 1.25rem;
    height: 35px;
    margin-right: 0;
}

main.flex-container {
    display: flex;
    flex-direction: column;
    height: 100%; // calc and set based on nav height
    width: 100%;
    overflow: auto;
    background: $gray-50;
}

.content-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    min-height: calc(100vh - 48px);
    overflow: auto;
    background: $gray-50;
}

content {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    background: $gray-50;
}

.outlet-container {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    //
    flex-direction: column;
}

@include media-breakpoint-down(md) {
    .content-container {
        flex-direction: column;
    }

    content {
        display: flex;
        flex: 1;
        overflow: auto;
    }
}

app-ui-alerts {
    flex: 0 0 auto;
    border-bottom: 1px solid $gray-300;
}

ng-component,
app-forgot-password,
app-self-registration,
app-aws-marketplace-registration,
app-azure-marketplace-registration,
app-confirm-email,
app-reset-password {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    width: 100%;
}

ng-component {
    background-color: $blue-gray-900;
}

app-navigation {
    display: flex;
    flex: 0 0 auto;
    background-color: $blue-gray-800;
}

.page-wrapper {
    flex: 1 1 auto;
    padding: $spacer;
    overflow: auto;
}

.list-wrapper {
    flex: 1 1 auto;
    overflow: auto;
    padding: $spacer;
}

/* Breadcrumbs */
.breadcrumb {
    background-color: $gray-200;
}

.breadcrumb-item.active {
    color: $body-color;
}

.breadcrumb-item+.breadcrumb-item::before {
    padding-right: 5px;
}

.breadcrumb-item.active::before {
    padding-right: 5px;
}

/* Title Bar */
.title-bar {
    flex: 0 0 auto;
    padding: calc($spacer/2) $spacer;
    border-bottom: 1px solid $gray-300;
    background: $white;
}

.title-bar .title,
.title-bar nav {
    display: inline-block;
    height: 100%;
}

.title-bar nav {
    height: auto;
}

.title-bar ol.breadcrumb {
    @extend h1;
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    text-transform: unset;
    line-height: 1.5;
}

.title-bar .actions {
    float: right;
}

/* Tabs */

.tab-container .tab-content {
    // display: flex;
    // overflow: auto;
    // flex: 1 1 auto;
	margin: 12px
}

.tab-container .nav-tabs {
    @extend .mt-1, .ms-2;
}


.tab-form {
    display: flex;
    overflow: auto;
    flex: 1 1 auto;
    flex-direction: column;
}

.bottom-bar {
    flex: 0 0 auto;
    background-color: $gray-50;
    padding: calc($spacer/2) $spacer;
    border-top: 1px solid $gray-300;
}

/* Login */
.login-container {
    flex: 1 1 auto;
    overflow: auto;
    padding: $spacer;
    background-color: $blue-gray-900;
    text-align: center;
}

.login-container p {
    color: $white;
}

.login-container .card-body {
    padding: $spacer * 2;
}

/* Navbar */
.bg-dark {
    background-color: $blue-gray-900 !important;
}

.bg-dark.impersonating {
    background-color: mix($blue-gray-900, $status-error, 70%) !important;
}

.bg-dark .btn-secondary {
    background-color: $zixi-green;
    color: $body-color;
}

.bg-dark .btn-secondary:hover {
    color: $body-color;
    background-color: darken($zixi-green, 7.5%);
    border-color: darken($zixi-green, 10%);
}

.bg-dark .btn-secondary:not(:disabled):not(.disabled):active,
.bg-dark .btn-secondary:not(:disabled):not(.disabled).active,
.bg-dark .show>.btn-secondary.dropdown-toggle {
    color: $body-color;
    background-color: $zixi-green;
    border-color: $zixi-green;
}

/* Navigation */
$expanded-width: 11rem;

.navigation-container {
    flex: 1;
    border-right: 1px solid $blue-gray-900;
}

.navigation-container.isMobile {
    border-right: none;
}

.navigation-container .navigation {
    display: block;
    position: relative;
    text-align: center;
    z-index: 4;
    width: $expanded-width;
}

.navigation-container.isMobile .navigation {
    width: 100%;
}

.navigation-container .navigation .nav.flex-column {
    text-align: left;
}

.navigation-container .navigation .nav.flex-column li.nav-item a.nav-link,
.navigation-container .navigation .nav.flex-column li.nav-item div.nav-link.hasSublinks {
    width: $expanded-width + 0.05rem;
    background-color: $blue-gray-800;
    border-bottom: 1px solid $blue-gray-700;
    border-right: 1px solid $blue-gray-900;
    color: $blue-gray-100;
    padding-top: calc($spacer/2).5;
    padding-bottom: calc($spacer/2).5;
    padding-left: 0.9rem;
}

.navigation-container .navigation .nav.flex-column li.nav-item div.nav-link.hasSublinks {
    color: $blue-gray-300;
    // border-bottom: none;    //padding-bottom: 0;
}

// .navigation-container .navigation .nav.flex-column li.nav-item ul.nav.sublink li.nav-item:not(:last-child) a.nav-link {
//     border-bottom: none;
// }
.navigation-container.isMobile .navigation .nav.flex-column li.nav-item a.nav-link,
.navigation-container.isMobile .navigation .nav.flex-column li.nav-item div.nav-link.hasSublinks {
    width: 100%;
    border-right: none;
    padding-top: calc($spacer/2);
    padding-bottom: calc($spacer/2);
}

.navigation-container.isMobile .navigation .nav.flex-column li.nav-item ul.nav li.nav-item {
    width: 100%;
}

.navigation-container .navigation .nav.flex-column li.nav-item a.nav-link:hover {
    background-color: $blue-gray-900;
}

.navigation-container .navigation .nav.flex-column li.nav-item a.nav-link.active {
    background-color: $blue-gray-700;
    color: $white;
    padding-left: 0.65rem;
    border-left: 0.25rem solid $blue-200;
}

.navigation-container .navigation .nav.flex-column li.nav-item a.nav-link fa-icon,
.navigation-container .navigation .nav.flex-column li.nav-item div.nav-link.hasSublinks fa-icon {
    margin-right: 0.4rem;
}

.navigation-container .navigation .nav.flex-column li.nav-item ul.nav li.nav-item a.nav-link {
    padding-left: 1.25rem;
}

.navigation-container .navigation .nav.flex-column li.nav-item ul.nav li.nav-item a.nav-link.active {
    padding-left: 1rem;
}

/* Buttons */
.btn {
    background-color: $white;
}

.btn-link {
    background-color: transparent;
}

.navbar .btn-link {
    color: $white;
}

.btn-nav-toggle {
    margin-right: calc($spacer/2);
    margin-left: -$spacer;
    border-right: 1px solid $blue-gray-700;
    border-radius: 0;
}

.btn-nav-toggle .fa-lg {
    font-size: 1.4rem;
}

.btn-warning {
    color: $white;
    background-color: $status-warning;
}

.btn-info {
    background-color: $status-info;
}

.btn-primary {
    background-color: $primary;
}

.btn-secondary {
    background-color: $secondary;
}

.btn-danger {
    background-color: $status-error;
}

.btn-success {
    background-color: $status-good;
}

.btn-outline-warning:hover {
    color: $white;
}

.btn-outline-navbar.green {
    color: $zixi-green;
    border-color: $zixi-green;
    background-color: transparent;
}

.btn-outline-navbar.green:hover {
    color: $black;
    background-color: $zixi-green;
    border-color: $zixi-green;
}

.btn-outline-navbar {
    color: $blue-200;
    border-color: $blue-200;
    background-color: transparent;
}

.btn-outline-navbar:hover {
    color: $black;
    background-color: $blue-200;
    border-color: $blue-200;
}

.logout-button {
    margin-right: calc($spacer / -2);
}

.btn-outline-light {
    color: $white;
    background-color: transparent;
}

.input-group .input-group-text .btn-outline-light {
    border-color: $gray-400;
    border-left-color: $white;
    color: $body-color;
    background-color: $white;
}

.input-group .input-group-text .btn-outline-light:not(:disabled):not(.disabled):active,
.input-group .input-group-text .btn-outline-light:not(:disabled):not(.disabled).active,
.input-group .input-group-text .show>.btn-outline-light.dropdown-toggle {
    color: $body-color;
    background-color: $white;
    border-color: $gray-400;
    border-left-color: $white;
}

.deselect-rows-btn {
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px dotted $body-color;
    height: 20px;
    line-height: 19px;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
    cursor: pointer;
}

.btn-xs,
.btn-group-xs>.btn {
    line-height: 1;
}

.btn-outline-primary.disabled.active {
    color: $white;
    background-color: $primary;
}

.dropdown-menu {
    border: 1px solid $gray-300;
}

/* Table */
.table {
    background: $white;
}

.table th {
    font-weight: $font-weight-semibold;
}

.table thead th {
    border-bottom-width: 1px;
}

.table.bordered {
    border-left: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;
}

.table.bordered.is-invalid {
    border: 1px solid $status-error;
}

.table.bordered.is-invalid th {
    border-top: 1px solid $status-error;
}

.table tr td.ellipsis.link-only a {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table tr td a:hover {
    text-decoration: none;
}

.comma-list span:after {
    content: ", ";
    white-space: pre;
}

.comma-list span:last-child:after {
    content: none;
}

.table-dark {
    color: $body-color;
}

.table-hover tbody tr:hover {
    background-color: $gray-200;
}

.table-responsive .table thead tr,
.table-responsive .table tbody tr {
    white-space: nowrap;
}

/* Table - Small */
.card-body .table-sm th:first-child,
.card-body .table-sm td:first-child {
    padding-left: 0.75rem;
}

.card-body .table-sm th:last-child,
.card-body .table-sm td:last-child {
    padding-right: 0.75rem;
}

/* Table - Selectable */
table.table-select tbody tr {
    cursor: pointer;
}

table.table-select tbody tr.selected {
    background-color: $active-bg-color;
}

/* Form */
label {
    font-weight: $font-weight-semibold;
    margin-bottom: calc($spacer/4);
}

.form-control-plaintext {
    padding: 0;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.form-control-plaintext:focus {
    outline: none;
}

.form-control-sm {
    font-size: $font-size-base;
    height: auto;
    line-height: 1;
    padding-top: calc($spacer/8);
    padding-bottom: calc($spacer/8);
}

.custom-control.custom-checkbox,
.custom-control.custom-radio {
    padding-left: $spacer * 1.25;
}

fieldset .custom-control.custom-checkbox,
fieldset .custom-control.custom-radio {
    margin-bottom: calc($spacer/4);
}

.custom-control-label {
    font-weight: normal;
}

.custom-control-label::before,
.custom-control-label::after {
    left: -$spacer * 1.25;
}

fieldset legend {
    display: inline-block;
    margin-bottom: calc($spacer/2);
    font-size: $font-size-base;
    font-weight: $font-weight-semibold;
}

fieldset.bordered {
    border: 1px solid $gray-400;
    padding: calc($spacer/3.5) $spacer calc($spacer/2) $spacer;
    clear: both;
    border-radius: $border-radius;
}

fieldset.bordered>legend {
    margin-left: -17px;
    margin-bottom: 0;
    width: auto;
    padding-right: calc($spacer/2);
}

.is-invalid~.invalid-feedback {
    display: block;
}

fieldset.is-invalid .invalid-feedback {
    display: block;
}

.is-invalid .custom-control-input:valid~.custom-control-label {
    color: $status-error;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
    padding-right: 0.75rem;
}

.input-group.is-invalid .btn-outline-primary {
    color: $status-error;
    border-color: $status-error;
}

.valid-pair {
    width: 100%;
    min-height: 20px;
    margin-top: 0;
}

.input-group>.input-group-text>.btn:last-child {
    border-top-right-radius: calc($spacer/4);
    border-bottom-right-radius: calc($spacer/4);
}

.input-group-text {
    background-color: transparent;
}

.input-group>.input-group-div {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

hr {
    border-top-color: $gray-300;
}

.append-label {
    font-weight: $font-weight-normal;
    margin-bottom: 0;
    margin-top: calc($spacer/2);
}

/* Pre */
pre {
    padding: $spacer;
    border: 1px solid $gray-300;
    background-color: $white;
}

/* Alerts */
.alert-light {
    color: $body-color;
    background-color: $gray-100;
    border-color: $gray-300;
}

.alert-light .alert-link {
    color: $body-color;
}

/* Dropdown */
.dropdown-header {
    padding-top: calc($spacer/2);
    padding-bottom: calc($spacer/2);
}

.dropdown-item {
    border-top: 1px solid $gray-300;
}

.dropdown-item:first-child {
    border-top: none;
}

.dropdown-item.desc:after,
.dropdown-item.asc:after {
    fill: $white;
    color: $white;
    content: "";
    display: inline-block;
    position: relative;
    left: 0.25rem;
    top: 0.2rem;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23ffffff' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' class=''%3e%3c/path%3e%3c/svg%3e");
    background-repeat: no-repeat;
    width: $spacer;
    height: $spacer;
}

.dropdown-item.desc:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23ffffff' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
}

.text-primary.dropdown-item.active,
.text-primary.dropdown-item:active {
    color: #fff !important;
}

.text-secondary.dropdown-item.active,
.text-secondary.dropdown-item:active {
    color: #fff !important;
    background-color: $secondary;
}

.text-warning.dropdown-item.active,
.text-warning.dropdown-item:active {
    color: #fff !important;
    background-color: $warning;
}

.text-danger.dropdown-item.active,
.text-danger.dropdown-item:active {
    color: #fff !important;
    background-color: $status-error;
}

/* Modal */
.modal-header {
    padding: calc($spacer/2) $spacer;
}

.modal-header .close {
    padding: calc($spacer/1.5) $spacer;
    margin-top: calc($spacer/-2);
}

.modal-body p:last-child {
    margin-bottom: 0;
}

.modal-footer {
    padding: calc($spacer/2) $spacer;
}

.modal-content div.loading {
    opacity: 0.5;
}

/* Select */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%231976d2' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: bottom 50% right 0.75rem;
    background-size: 9px;
}

select::-ms-expand {
    display: none;
}

select.form-control[multiple] {
    background-image: none;
}

/* Card */
.card {
    border: 1px solid $gray-300;
}

.card .card-header {
    padding-left: $spacer;
    padding-right: $spacer;
}

.card .card-body {
    padding: $spacer;
}

.card .card-body.no-padding-btm {
    padding-bottom: 0;
}

.card .form-group:last-child {
    margin-bottom: 0;
}

.card.bg-light {
    background-color: $white !important;
}

form .card.bg-light .card-header {
    background-color: $primary;
    color: $white;
}

.card-sm .card-header {
    padding: calc($spacer/2) calc($spacer/1.5);
}

.card-sm .card-body {
    padding: calc($spacer/2) calc($spacer/1.5);
}

/* Tooltip */
.tooltip .tooltip-inner {
    box-shadow: $box-shadow-sm;
    max-width: 260px;
}

.tooltip .tooltip-inner a {
    color: $white;
    text-decoration: underline;
}

.tooltip .tooltip-inner hr {
    margin-top: calc($spacer/2);
    margin-bottom: calc($spacer/2);
}

/* Ng-Select */
.ng-select.form-control {
    // Remove default form-control styling on ng-select
    height: unset;
    padding: unset;
    font-size: unset;
    font-weight: unset;
    line-height: unset;
    color: unset;
    background-color: unset;
    background-clip: unset;
    border: unset;
    border-radius: unset;
    transition: unset;
}

.ng-select.form-control.is-invalid {
    border-color: unset;
    padding-right: unset;
    background-image: unset;
    background-repeat: unset;
    background-position: unset;
    background-size: unset;
}

.ng-select .ng-select-container {
    min-height: 35px;
    border-radius: $border-radius;
    border: 1px solid $gray-400;
    color: $body-color !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
}

.ng-select.form-control.is-invalid .ng-select-container,
.is-invalid ng-select .ng-select-container {
    border: 1px solid $red;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $gray-400;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    top: unset;
}

.ng-select .ng-select-container:hover {
    box-shadow: none;
}

.ng-select.ng-select-opened>.ng-select-container {
    border-color: $gray-400;
}

.is-invalid ng-select.ng-select.ng-select-opened>.ng-select-container {
    border-color: $red;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    margin-bottom: 0;
}

.ng-dropdown-panel.ng-select-bottom {
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-top-color: $gray-400;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: $white;
    color: $body-color;
    padding: calc($spacer/3) calc($spacer/2);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: $hover-bg-color;
    color: $body-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: $body-color;
    background-color: $active-bg-color;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
    padding: calc($spacer/3) calc($spacer/2);
    font-weight: $font-weight-semibold;
    color: $gray-600;
    border-top: 1px solid $gray-300;
    border-bottom: none;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
    padding-left: calc($spacer/1.5);
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: $active-bg-color;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
    border-right: 1px solid $blue-100;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
    background-color: $blue-100;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: $primary transparent transparent;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
    border-color: $status-none transparent transparent;
}

.ng-select.ng-select-disabled>.ng-select-container {
    opacity: $btn-disabled-opacity;
}

.ng-select.form-control.is-invalid .ng-arrow-wrapper .ng-arrow,
.is-invalid ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: $red transparent transparent;
}

.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    border-color: transparent transparent $blue;
}

.ng-select.ng-select-opened.form-control.is-invalid>.ng-select-container .ng-arrow,
.is-invalid ng-select.ng-select.ng-select-opened>.ng-select-container .ng-arrow {
    border-color: transparent transparent $red;
}

.ng-select.ng-select-single.form-control-sm .ng-select-container {
    height: 27.8px;
    line-height: 1;
}

.ng-select.form-control-sm .ng-select-container {
    min-height: 26.8px;
}

.ng-select.form-control-sm .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: calc($spacer/4);
    padding-bottom: calc($spacer/4);
}

body>.ng-dropdown-panel.form-control-sm {
    padding: 0;
    margin-left: calc($spacer/2);
}

/* Useful Classes */
.app-title {
    @extend h1;
    color: $white;
    margin-bottom: 0;
}

.h-0 {
    height: 0 !important;
}

.ng-invalid:not(form) {
    border: 1px solid $status-error;
    /* red */
}

/* Content Loading Animation */
content-loading-animation {
    display: flex;
    flex: 1 1 auto;
}

content-loading-animation.block {
    display: block;
    position: relative;
    min-height: 100px;
}

.content-loading {
    position: relative;
    flex: 1 1 auto;
}

content-loading-animation.block .content-loading {
    position: absolute;
    left: 50%;
    top: 50%;
}

.content-loading .spinner-border {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
}

.content-loading .dots-loader {
    top: 50%;
    left: 50%;
    margin-top: -4px;
    margin-left: -4px;
}

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a:active {
    text-decoration: none;
}

.form-group{
	@extend .mb-1;
}

table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc {
    color: $zixi-green;
}

#nav-tab>.nav-item {
    @extend .ps-2,
    .pe-2;
}


// *************************************
// groups of classes bundled together for cleaner code and easy changes
// use cp- as prefix on these so they're clearly exclusively from customer portal
// *************************************


/******** scaled font ********/
.cp-65 {
    font-size: .65rem;
}

.cp-75 {
    font-size: .75rem;
}

.cp-85 {
    font-size: .85rem;
}

.cp-110 {
    font-size: 1.1rem;
}

.cp-125 {
    font-size: 1.25rem;
}


// for form errors
.cp-form-error {
    @extend .alert,
    .alert-danger,
    .p-2,
    .mb-2;
}

// for property lists with objects
.cp-property-list {
    @extend .bg-light,
    .border,
    .cp-general-width-limit,
    .p-2,
    .mb-2;
}

// for property lists with objects
.cp-property-block {
    @extend .mb-1;
}

// for a property's label
.cp-property-label {
    margin-right: 4px;
}

// for a property's value
.cp-property-value {
    font-weight: bold;
}

// for to render multi-line plain text
.cp-pre-text {
    white-space: pre-line;
}

// for an inline scroll on overflow box
.cp-inline-release-notes-short {
    @extend .overflow-auto,
    .border,
    .p-1;
    max-height: 20ex;
    white-space: pre-line;
}

// for an inline scroll on overflow, a little longer
.cp-inline-release-notes-long {
    @extend .overflow-auto,
    .border,
    .p-1;
    max-height: 40ex;
    white-space: pre-line;
}

.cp-inline-release-notes-very-long {
    @extend .overflow-auto,
    .border,
    .p-1;
    max-height: 60ex;
    white-space: pre-line;
}

// for columns with text that might be long that should be chopped when space is limited
.cp-chopped-column {
    @extend .text-nowrap;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cp-chopped-column:hover {
    overflow: visible;
}

.cp-chopped-column-long {
    @extend .text-nowrap;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cp-chopped-column-long:hover {
    overflow: visible;
}

.cp-chopped-column-no-hover {
    @extend .text-nowrap;
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cp-chopped-column-long-no-hover {
    @extend .text-nowrap;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cp-key {
    @extend .font-monospace;
}

.cp-download-btn {
    @extend .btn,
    .btn-outline-info,
    .btn-sm;
}

.cp-download-document-btn {
    @extend .btn,
    .btn-outline-dark,
    .btn-sm;
}

.cp-link-btn {
    @extend .btn,
    .btn-outline-primary,
    .btn-sm;
}

.cp-icon-header {
    @extend .text-center;
    padding-right: 0.3rem !important;
    width: 20px !important;
}

.cp-icon-header:before,
.cp-icon-header:after {
    content: none !important;
}

.cp-icon-cell {
    @extend .text-center;
    //   max-width:14px;
}

.cp-hidden-content {
    display: none;
}

.cp-selected-primary {
    @extend .bg-primary,
    .text-white;
}

.cp-selected-secondary {
    @extend .bg-secondary,
    .text-white;
}

.cp-download-list-width {
    max-width: 800px;
}

.cp-shared-nav-item {
    color: $white;
}

.cp-admin-nav-item {
    color: $blue-200;
}

.cp-user-nav-item {
    color: $zixi-green;
}

.cp-general-width-limit {
    max-width: 600px;
}

.cp-wider-width-limit {
    max-width: 800px;
}

.cp-width-limit-1400 {
    max-width: 1400px;
}

.cp-width-limit-1000 {
    max-width: 1000px;
}

.cp-light-message {
	@extend .text-black, .bg-light, .rounded, .cp-general-width-limit, .p-2, .mb-1, .border, .border-2, .border-dark;
}

.cp-info-message {
	@extend .text-white, .bg-info, .rounded, .cp-general-width-limit, .p-2, .mb-1, .border, .border-2, .border-dark;
}

.cp-success-message {
	@extend .text-white, .bg-success, .rounded, .cp-general-width-limit, .p-2, .mb-1, .border, .border-2, .border-dark;
}

.cp-warning-message {
	@extend .text-white, .bg-warning, .rounded, .cp-general-width-limit, .p-2, .mb-1, .border, .border-2, .border-dark;
}

.cp-danger-message {
	@extend .text-white, .bg-danger, .rounded, .cp-general-width-limit, .p-2, .mb-1, .border, .border-2, .border-dark;
}

.cp-danger-message a, .cp-warning-message a, .cp-info-message a, .cp-success-message a{
	@extend .p-1;
	background-color: #ccc !important;
	
}

.cp-auto-width {
    width: auto;
}

.cp-75p-max-width {
    max-width: 75%;
}

.cp-icon-color-reported {
    color: $indigo;
}

.cp-icon-color-usage {
    color: $teal;
}

.cp-icon-color-dormant {
    color: $orange;
}

.cp-clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.cp-scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

.cp-dashboard-banner {
    @extend .fw-bold;
}

.cp-dashboard-msg {
    @extend .border-top,
    .border-dark,
    .pt-1,
    .pb-1,
    .cp-pre-text;
}

.cp-dashboard-item {
    @extend .border-top,
    .border-dark,
    .pt-1,
    .pb-1,
    .text-nowrap,
    .text-truncate;
}

.cp-dashboard-block-msgs {
    @extend .overflow-auto,
    .border,
    .col-10,
    .mb-2,
    .ms-2;
    max-height: 325px;
}

.cp-dashboard-block-other {
    @extend .overflow-auto,
    .border,
    .col-5,
    .mb-2,
    .ms-1,
    .me-1;
    max-height: 325px;
}

// .cp-dashboard-inner {
//     @extend .p-0;
// }

// .cp-dashboard-inner {
//     max-height: 325px;
//     @extend .overflow-auto,
//     .p-1,
//     .m-2,
//     .border;
// }

.cp-action-button-text {
    @extend .d-none,
    .d-md-inline-block,
    .ms-1;
}

.cp-tab-text {
    @extend .ms-1;
}

.cp-tab-badge {
    @extend .badge, .rounded-pill, .bg-primary, .align-top;
	font-size: .6rem !important
}

.cp-filter-block {
    @extend .form-group,
    .mb-2,
    .pe-1,
    .ps-1;
}

.cp-filter-block-2 {
    @extend .cp-filter-block,
    .col-lg-2,
    .col-md-2;
}

.cp-filter-block-3 {
    @extend .cp-filter-block,
    .col-lg-3,
    .col-md-3;
}

.cp-filter-block-4 {
    @extend .cp-filter-block,
    .col-lg-4,
    .col-md-4;
}

.cp-filter-block-5 {
    @extend .cp-filter-block,
    .col-lg-5,
    .col-md-5;
}

.cp-filter-block-6 {
    @extend .cp-filter-block,
    .col-lg-6,
    .col-md-6;
}

.cp-filter-block-7 {
    @extend .cp-filter-block,
    .col-lg-7,
    .col-md-7;
}

.cp-compact-form-group {
    @extend .form-group,
    .mb-2;
}

.cp-very-compact-form-group {
    @extend .form-group,
    .mb-1;
}

// for forms with stuff that pops open (ngselects, calendars)
// near the bottom of the form (when the buttons aren't fixed at bottom)
// and they get clipped
.cp-form-padding {
    height: 250px;
}

/******** popovers ********/

.cp-popover-banner {
    @extend .text-center,
    .border-bottom,
    .mb-2,
    .pb-2;
    font-weight: bold;
}

.cp-popover-line {
    white-space: pre-line;
}

.cp-popover-std {
    min-width: 480px;
    max-width: 480px;
}

/******** keys ********/
.cp-keysearch-specials {
    @extend .col-6;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 200px;
}

.cp-line-through {
    text-decoration: line-through;
}

.cp-usage-left {
    @extend .border, .text-start, .text-nowrap;
}

.cp-usage-center {
    @extend .border, .text-center, .text-nowrap;
}

.cp-usage-right {
    @extend .border, .text-end, .text-nowrap;
}

.cp-pointer {
    cursor: pointer;
}

.cp-zm-cert-small {
    @extend .img-fluid,
    .img-thumbnail,
    .rounded;
    max-height: 350px;
    max-width: 150px;
    // max-height: 250px
}

.cp-zm-cert-large {
    @extend .img-fluid,
    .img-thumbnail,
    .rounded;
    max-height: 350px;
    max-width: 250px;
}

.cp-table-orange {
    background-color: #ffb833 !important;
}

// New Stuff as part of BS5 conversion

.cp-std-tab{
    @extend .px-2, .text-uppercase, .fw-bold;
}

.cp-compact-tab{
    @extend .px-1, .text-uppercase, .fw-bold;
}

.cp-std-table {
    @extend .table,
    .table-hover,
    .table-sm,
    .table-bordered,
	.border
}

.cp-std-table td{
	@extend .p-1, .border;
}


.cp-form-buttons {
	@extend .fixed-bottom, .pt-2, .text-start, .bg-transparent;
	z-index: 30;
	padding-left: 180px
}

.cp-form-buttons-inner {
	@extend .mt-1, .py-1, .bg-body;
}

.cp-nav-dropdown-toggle{
	@extend .dropdown-toggle;
}

.cp-nav-dropdown-toggle::after {
	float:right;
}

.cp-zixi-answer{
	@extend .border, .p-2, .mt-1, .overflow-auto, .rounded;
	max-height: 300px;
	background-color: $gray-400 !important;
	color: black;
}

.cp-form-floating {
	@extend .form-floating, .border, .mb-1;
}

.cp-form-group-other {
    @extend .mb-1,
    .border,
    .p-2,
    .form-control;

	// background-color: $gray-700;
}

.cp-form-submit-button {
	@extend .btn, .btn-outline-success, .me-1;
}

.cp-form-delete-button {
	@extend .btn, .btn-outline-danger, .me-1;
}

.cp-form-cancel-button {
	@extend .btn, .btn-outline-secondary;
}

.cp-gray-bg, td.cp-gray-bg, tr.cp-gray-bg>td, tr.cp-gray-bg>th{
	// @extend .bg-body-tertiary;
	background-color: $gray-800;
	border-color: $gray-600;
}



app-user-list,
app-user-form,
app-user-bulk-form,
app-user,
app-my-settings,
app-my-settings-form,
app-change-password-form,
app-change-email-form,
app-organization-list,
app-organization,
app-organization-form,
app-organization-group-list,
app-organization-group,
app-organization-group-form,
app-user-group-list,
app-user-group,
app-user-group-form,
app-organization-bulk-form,
app-partnership-form,
app-organization-zen-domain-form,
app-import-sales-force-users,
app-platform-list,
app-platform-form,
app-platform,
app-product-list,
app-product-form,
app-product,
app-package-list,
app-package-form,
app-package-activation-form,
app-package,
app-build-list,
app-build,
app-build-form,
app-document-list,
app-document-form,
app-document,
app-file-list,
app-file-upload-form,
app-file-form,
app-file,
app-download-documents-table,
app-download-wrapper,
app-download-build,
app-download-document,
app-my-keys,
app-activation-form,
app-access-summary,
app-key-template-form,
app-build-notification-form,
app-my-packages {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    flex-direction: column;
}

app-user-form form,
app-auto-build-form form,
app-user-bulk-form form,
app-my-settings-form form,
app-change-password-form form,
app-change-email-form form,
app-platform-form form,
app-file-form form,
app-package-activation-form form,
app-partner-form form,
app-add-license-keys-form form,
app-license-key-form form,
app-link-keys-form form,
app-set-organization-form form,
app-set-template-form form,
app-document-form form,
app-share-license-keys-form form,
app-add-license-keys-for-user-form form,
app-system-message-form form,
app-licensing-product-form form,
app-licensing-property-form form,
app-licensing-property-sort form,
app-licensing-product-property-form form,
app-user-access-form form,
app-user-group-form form,
app-user-group-access-form form,
app-organization-billing-code-form form,
app-organization-billing-codes-form form,
app-offline-add-form form,
app-traffic-calc form,
app-add-journal-form form,
app-zenmaster-form form,
app-offline-convert-form form,
app-package-key-template-form form,
app-bundle-form form,
app-hostid-form form,
app-add-hostids-form form,
app-zenmaster-admin-form form,
app-zenmaster-add-form form,
app-zenmaster-enable-form form,
app-write-access-form form,
app-basic-send-form form,
app-protocol-set-form form,
app-commercial-form form,
app-snooze-form form,
app-zenmaster-marketplace-form form,
app-activation-marketplace-form form,
app-set-billing-code-admins-form form,
app-my-org-billing-codes-form form,
app-my-org-billing-code-form form
{
    flex: 1 1 auto;
    padding: $spacer;
    overflow: auto;
}

// $blue: $blue-700;
// $indigo: #673ab7;
// $purple: #9c27b0;
// $pink: #e91e63;
// $red: $status-error;
// $orange: $status-warning;
// $yellow: #ffeb3b;
// $green: $status-good;
// $teal: #009688;
// $cyan: $status-info;
// $dark: $gray-900;
html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}